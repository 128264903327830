export enum ScreeningStatus {
  Approved = 1,
  ApprovedWithConditions,
  Declined,
  FurtherReview,
  Pending,
  GuarantorRequired,
  DocumentSubmissionCompleted,
  Retry,
  ProblemResolution,
  ApprovedWithConditionsMet,
}

export const ScreeningStatusMap = {
  1: "Approved",
  2: "Approved with Conditions",
  3: "Declined",
  4: "Further Review",
  5: "Pending",
  6: "Guarantor Required",
  7: "Document Submission Completed",
  8: "Retry",
  9: "Problem Resolution",
  10: "Approved with Conditions Met",
};

export interface Issue {
  id: number;
  applicantScreeningId: number;
  type: string;
  description: string;
}

export const ScreeningTypes = {
  ssn: "SSN",
  address: "Address",
};

export const ScreeningDocumentResult = {
  clean: "Clean",
  insufficientDocumentation: "insufficient documentation",
  edited: "Edited",
  resubmittedClean: "Resubmitted - Clean",
  resubmittedInsufficient: "Resubmitted - Insufficient",
  resubmittedEdited: "Resubmitted - Edited",
  pendingDocumentationVerification: "Pending Document Verification",
};
